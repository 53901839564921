<template>
  <div class="score_check">
    <div class="tiaojian">
      <div class="filter">
        <div class="filter_check">筛选条件</div>
        <div>
          <el-select v-model="value1" clearable placeholder="班级">
            <el-option
              v-for="item in classNames"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <el-date-picker v-model="value2" type="month" placeholder="选择月份">
          </el-date-picker>
        </div>
      </div>
      <el-button type="primary" round :disabled="disable" @click="clickAction"
        >补考名单</el-button
      >
    </div>
    <div class="zhoushu" v-show="show">
      <div class="filter_check">周数:</div>
      <el-radio v-model="value3" label="1">第1周</el-radio>
      <el-radio v-model="value3" label="2">第2周</el-radio>
      <el-radio v-model="value3" label="3">第3周</el-radio>
      <el-radio v-model="value3" label="4">第4周</el-radio>
      <el-radio v-model="value3" label="5">第5周</el-radio>
    </div>
    <!-- 周考成绩查询 -->
    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 100%; min-width: 900px"
      row-key="id"
      border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column label="班级" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.className }}</span>
        </template>
      </el-table-column>
      <el-table-column label="周考名">
        <template slot-scope="scope">
          <span>{{ scope.row.zhoukaoName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="周考详情" width="130">
        <template slot-scope="scope">
          <span
            >{{ scope.row.zhoukao.split("-")[1] }}月第{{
              scope.row.zhoukao.split("-")[2]
            }}周</span
          >
        </template>
      </el-table-column>
      <el-table-column label="周考时间" width="130">
        <template slot-scope="scope">
          <span>{{ scope.row.riqi }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态操作" width="180">
        <template slot-scope="scope">
          <el-dropdown
            v-if="!scope.row.children"
            :hide-on-click="false"
            @command="commandAction"
          >
            <span class="el-dropdown-link">
              常用操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{ row: scope.row, kind: 1 }"
                >成绩查询</el-dropdown-item
              >
              <el-dropdown-item :command="{ row: scope.row, kind: 2 }"
                >成绩导入</el-dropdown-item
              >
              <el-dropdown-item :command="{ row: scope.row, kind: 3 }"
                >补考成绩导入</el-dropdown-item
              >
              <el-dropdown-item :command="{ row: scope.row, kind: 4 }"
                >周考考题</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="page" style="text-align: right; padding: 20px 0">
      <el-pagination
        small
        layout="prev, pager, next"
        :total="count"
        :page-size="10"
        @current-change="changeAction"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ScoreCheck",
  data() {
    return {
      tableData: [],
      sourceData: [],
      count: 0,
      value1: "",
      value2: "",
      value3: "1",
      show: false,
      disable: true,
      classes:[]
    };
  },
  watch: {
    value1() {
      this.filterClass();
    },
    value2(newV) {
      this.filterClass();
      this.show = newV ? true : false;
      this.disable = newV ? false : true;
    },
    value3() {
      this.filterClass();
    },
  },
  computed: {
    ...mapState(["loading"]),
    classNames() {
      let classes = [];
      for (let i in this.sourceData) {
        let item = this.sourceData[i];
        if (classes.indexOf(item.className) == -1) {
          classes.push(item.className);
        }
      }
      return classes;
    },
  },
  async created() {
    let res = await this.$datas.getClass;
    let result = await this.$datas.getTms;
    let res1 = result.data;
    this.count = result.data.total;
    let tableData = res.data.data;
    this.classes = tableData;
    let tms = [];
    for (let i in res1.data) {
      for (let j in tableData) {
        if(tableData[j].className == res1.data[i].t_class.className){
          tms.push({
            ...res1.data[i],
            className: tableData[j].className,
          });
          break;
        }
      }
    }
    tms.sort((item1, item2)=>{
        let className1 = item1.className.replace(/[A-Z]+/, "");
        let className2 = item2.className.replace(/[A-Z]+/, "");
        return className2-className1;
    });
    tms.sort((item1, item2)=>{
        return item2.riqi > item1.riqi ? 1:-1;
    });
    this.tableData = tms;
    this.sourceData = tms;
    for(let item in tms){
      console.log('==================');
      console.log(tms[item].zhoukao);
      console.log(JSON.parse(tms[item].timu));
    }
  },
  methods: {
    filterClass() {
      let datas = [];
      for (let i in this.sourceData) {
        let item = this.sourceData[i];
        let c_class = item.zhoukao.split("-")[0];
        let c_month = item.zhoukao.split("-")[1];
        let c_week = item.zhoukao.split("-")[2];
        if (this.value1 && this.value2) {
          if (
            c_class == this.value1 &&
            c_month == this.value2.getMonth() + 1 &&
            c_week == this.value3
          ) {
            datas.push(item);
          }
        } else if (this.value1 && !this.value2) {
          if (c_class == this.value1) {
            datas.push(item);
          }
        } else if (!this.value1 && this.value2) {
          if (c_month == this.value2.getMonth() + 1 && c_week == this.value3) {
            datas.push(item);
          }
        } else {
          datas = this.sourceData;
        }
      }
      this.tableData = datas;
    },
    async changeAction(num) {
      this.$datas.meta = { page: num };
      let result = await this.$datas.getTms;
      let res1 = result.data;
      this.count = result.data.total;
      let tableData = this.classes;
      let tms = [];
      for (let i in res1.data) {
        for (let j in tableData) {
          if(tableData[j].className == res1.data[i].t_class.className){
            tms.push({
              ...res1.data[i],
              className: tableData[j].className,
            });
            break;
          }
        }
      }
      tms.sort((item1, item2)=>{
          let className1 = item1.className.replace(/[A-Z]+/, "");
          let className2 = item2.className.replace(/[A-Z]+/, "");
          return className2-className1;
      });
      tms.sort((item1, item2)=>{
        return item2.riqi > item1.riqi ? 1:-1;
      });
      this.tableData = tms;
      this.sourceData = tms;
    },
    commandAction(row) {
      console.log(row);
      if (row.kind == 2) {
        this.$router.push({
          name: "ZhouKaoScore",
          params: { className: row.row.className },
          query: { item: row.row },
        });
      } else if (row.kind == 3) {
        this.$router.push({
          name: "ZhouKaoScoreUpdate",
          params: { className: row.row.className },
          query: { item: row.row },
        });
      } else if (row.kind == 1) {
        this.$router.push({
          name: "ZhouKaoScoreCheck",
          params: { className: row.row.className },
          query: { item: row.row },
        });
      } else {
        this.$router.push({
          name: "PDF",
          query: {
            datas: { tms: JSON.parse(row.row.timu) },
            zhoukaoName: row.row.zhoukaoName,
            daan: true,
          },
        });
      }
    },
    async handleEdit(index, row) {
      console.log(index, row);
      this.$router.push({ name: "ZhouKaoScore", query: { scores: row } });
    },
    async handleDelete(index, row) {
      this.$datas.meta = { id: row.id };
      let res = await this.$datas.deleteClass;
      if (res.status >= 200 && res.status < 300) {
        this.$alert("班级已删除", "提示", {
          confirmButtonText: "确定",
        });
        //清除数据源
        this.tableData.splice(index, 1);
      }
    },
    clickAction() {
      let query = [];
      for (let i in this.sourceData) {
        let item = this.sourceData[i];
        let c_month = item.zhoukao.split("-")[1];
        let c_week = item.zhoukao.split("-")[2];
        if (c_month == this.value2.getMonth() + 1 && c_week == this.value3) {
          query.push(item);
        }
      }
      this.$router.push({
        name: "BuKaoMingDan",
        query: { datas: query },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.filter,
.zhoushu {
  display: flex;
  width: 50%;
  margin: 15px 0;
  justify-content: space-between;
  align-items: center;
}
.tiaojian {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter_check {
  font-size: 14px;
  color: #555;
}
</style>
